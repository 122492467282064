import { Background, NavPanel, Headline, NextPage, TopRow } from '../Styled'
import styled from 'styled-components'
import React from 'react'
import NextArrowImg from '../../images/black_arrow.svg'
import HQPhotograph from '../../images/contact.jpg'

export default function Contact() {
    function scrollToMarra() {
        window.scrollBy({left: 0, top: -(window.innerHeight * 2), behavior: 'smooth'})
    }
    function scrollToSL() {
        window.scrollBy({left: 0, top: -(window.innerHeight * 1), behavior: 'smooth'})
    }
    function scrollToLH() {
        window.scrollBy({left: 0, top: -(window.innerHeight * 3), behavior: 'smooth'})
    }

    return (
        <LH>
            <TopRow>
                <Title>Logical Holdings</Title>
                <NavPanelContact>
                    <ul>
                        <li><button onClick={() => scrollToLH()}>Logical Holdings</button></li>
                        <li><button onClick={() => scrollToMarra()}>Marra</button></li>
                        <li><button onClick={() => scrollToSL()}>Scott Logic</button></li>
                        <li className="active">Contact Us</li>
                    </ul>
                </NavPanelContact>
            </TopRow>
            <ContactHeadline>Contact Us</ContactHeadline>
            <LHHeadline>Please get in touch if you'd like<br />to know more about us</LHHeadline>
            
            <TwoColumns>
                <Left>
                <HQPhoto src={HQPhotograph} />
                </Left>
                <Right>
                    <AddressHeading>LOGICAL HOLDINGS</AddressHeading>
                    <AddressMain>6th Floor, The Lumen,</AddressMain>
                    <AddressMain>St. James Boulevard,</AddressMain>
                    <AddressMain>Newcastle upon Tyne</AddressMain>
                    <AddressMain>NE4 5BZ</AddressMain>
                    <br />
                    <AddressMain>0191 2495509</AddressMain>
                    <AddressHeading><a href="mailto:gary@logical.holdings">info@logical.holdings</a></AddressHeading>
                </Right>
            </TwoColumns>

            <NextPage onClick={() => scrollToLH()}>
                <NextArrow src={NextArrowImg} />
                <NextPageText>Back To Top</NextPageText>
            </NextPage>
            <CompanyNumber>Registered Company Number: 11688287</CompanyNumber>
        </LH>
    )
};

const LH = styled(Background)`
    background: white;
`

const Title = styled.h1`
    color: black;
    
    :hover {
        cursor: default;
    }
`

const ContactHeadline = styled(Headline)`
    font-weight: bold;
    font-size: 48px;
    margin: 0;
    margin-bottom: 28px;
    padding: 0;
    font-family: "Red Hat Text", sans-serif;

    @media (max-width: 610px) {
        font-size: 38px;
        margin-bottom: 8px;
    }

    @media (max-height: 750px) {
        font-size: 38px;
        margin-bottom: 10px;
    }
`

const LHHeadline = styled(Headline)`
    color: black;
    font-size: 35px;
    max-width: 760px;
    font-weight: 500;
    line-height: 48px;
    font-family: "Red Hat Text", sans-serif;

    margin: 0;
    margin-bottom: 36px;
    padding: 0;
    @media (max-width: 610px) {
        font-size: 24px;
        margin-bottom: 0;
        line-height: 28px;
    }

    @media (max-height: 750px) {
        font-size: 28px;
        line-height: 36px;
    }

    @media (max-height: 550px) {
        margin-bottom: 0;
    }
`

const NextPageText = styled.h3`
    color: black;
    font-family: "Red Hat Text", sans-serif;

    @media (max-width: 440px) {
        font-size: 22px;
    }

    @media (max-height: 750px) {
        font-size: 22px;
    }
`

const NextArrow = styled.img`
    margin-bottom: 14px;
    padding: 0;
    transform: rotate(180deg);
`

const CompanyNumber = styled.p`
    position: absolute;
    left: 20px;
    bottom: 0;
    font-family: "Red Hat Text", sans-serif;
    color: black;
    font-size: 12px;
`

const TwoColumns = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 64px;
    padding: 24px;

    @media (max-width: 610px) {
        gap: 0;
        flex-direction: column;
        align-items: center;
    }

    @media (max-height: 550px) {
        padding: 10px 24px 24px 24px;
    }
`

const HQPhoto = styled.img`
    height: 250px;
    width: auto;

    @media (max-width: 610px) {
        height: 180px;

    }

    @media (max-height: 750px) {
        height: 180px;
    }
`

const Left = styled.div`

`

const Right = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 8px;
`

const AddressHeading = styled.h2`
    font-weight: bold;
    font-family: "Red Hat Text", sans-serif;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
    a {
        color: black;
        text-decoration: none;

        :hover {
            text-decoration: underline;
        }
    }

    @media (max-height: 750px) {
        font-size: 16px;
        line-height: 22px;
    }
`

const AddressMain = styled.p`
    margin 0;
    font-family: "Red Hat Text", sans-serif;
    font-size: 20px;
    line-height: 28px;

    @media (max-height: 750px) {
        font-size: 16px;
        line-height: 22px;
    }
`

const NavPanelContact = styled(NavPanel)`
    li {
        color: black;
    }

    li button {
        color: black;
    }

    li.active::before {
        color: black;
        position: absolute;
        left: -22px;
    }
`   