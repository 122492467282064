import './App.css';
import LogicalHoldings from './components/pages/LogicalHoldings'
import Marra from './components/pages/Marra'
import ScottLogic from './components/pages/ScottLogic'
import Contact from './components/pages/Contact'
import React from 'react'

function App() {
  return (
    <div className="App">
      <LogicalHoldings />
      <Marra />
      <ScottLogic />
      <Contact />
    </div>
  );
}

export default App;
