import styled from 'styled-components'

export const Background = styled.div`
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Headline = styled.h2`
    text-align: center;
    padding: 24px;
    font-size: 36px;
    max-width: 780px;
    font-weight: 500;

    @media (max-width: 440px) {
        margin: 0;
    }
`;

export const NavPanel = styled.div`
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    li {
        color: white;
        margin: 8px 0;
        font-size: 18px;
        font-family: "Red Hat Text", sans-serif;
        transition: 0.2s;
        text-decoration: none;
    }

    li button {
        color: white;
        font-size: 18px;
        background: none;
        border: none;   
        padding: 0;
        font-family: "Red Hat Text", sans-serif;
        transition: 0.2s;
        text-decoration: none;
    }

    li:hover,
    li button:hover {
        text-decoration: underline;
        cursor: pointer;
        transition: 0.2s;
    }

    li.active {
        font-weight: 700;
        position: relative;
    }

    li.active::before {
        content: "•";
        position: absolute;
        left: -22px;
        color: white;
    }

    li.indented {
        margin-left: 22px;
    }

    @media (max-width: 670px) {
        display: none;
    }
`;

export const NextPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 29px;
    margin: 0 auto;
    z-index: 1;

    :hover {
        cursor: pointer;
    }

    :hover h3 {
        text-decoration: underline;
    }
`

export const TopRow = styled.div`
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 42px 88px;

    @media (max-width: 450px) {
        padding: 42px 24px;
    }
`

export const VisitButton = styled.a`
    border: 1px solid #1B1C1D;
    font-weight: 700;
    padding: 14px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    text-decoration: none;
    color: black;
    background: none;
    transition: 0.2s;
    font-family: "Montserrat", sans-serif;
    z-index: 1;

    :hover {
        transition: 0.2s;
        background: black;
        color: white;
    }

    @media (max-width: 440px) {
        font-size: 18px;
        padding: 10px 18px;
    }
`