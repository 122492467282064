import { Background, NavPanel, Headline, NextPage, TopRow, VisitButton } from '../Styled'
import styled from 'styled-components'
import SLLogoImg from '../../images/sl-logo-new.svg'
import React from 'react'
import NextArrowImg from '../../images/next_arrow.svg'
import BottomLeftImg from '../../images/sl_left.svg'
import BottomRightImg from '../../images/sl_right.svg'

export default function ScottLogic() {
    function scrollToMarra() {
        window.scrollBy({left: 0, top: -(window.innerHeight), behavior: 'smooth'})
    }
    function scrollToLH() {
        window.scrollBy({left: 0, top: -(window.innerHeight * 2), behavior: 'smooth'})
    }
    function scrollToContact() {
        window.scrollBy({left: 0, top: (window.innerHeight * 1), behavior: "smooth"})
    }

    return (
        <LH>
            <TopRow>
                <Title>Logical Holdings</Title>
                <NavPanel>
                    <ul>
                        <li><button onClick={() => scrollToLH()}>Logical Holdings</button></li>
                        <li><button onClick={() => scrollToMarra()}>Marra</button></li>
                        <li className="active">Scott Logic</li>
                        <li><button onClick={() => scrollToContact()}>Contact Us</button></li>
                    </ul>
                </NavPanel>
            </TopRow>
            <SLLogo src={SLLogoImg} />
            <LHHeadline>All Together Smarter</LHHeadline>
            <LHSubHeadline>Expertly Technical, Reassuringly Human</LHSubHeadline>
            <VisitSL href="http://scottlogic.com" target="_blank">Visit scottlogic.com</VisitSL>
            <NextPage onClick={() => scrollToContact()}>
                <NextPageText>Contact Logical Holdings</NextPageText>
                <NextArrow src={NextArrowImg} />
            </NextPage>
            <BottomLeft src={BottomLeftImg} />
            <BottomRight src={BottomRightImg} />
        </LH>
    )
};

const LH = styled(Background)`
    background: #2BAFBA;
`

const Title = styled.h1`
    color: white;
`

const LHHeadline = styled(Headline)`
    color: white;
    font-family: "objektiv-mk1", sans-serif;
    font-weight: 500;
    font-size: 38px;
    max-width: 970px;   
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 12px;
    padding-bottom: 8px;

    @media (max-width: 400px) {
        font-size: 24px;
    }
`

const LHSubHeadline = styled(Headline)`
    color: white;
    font-family: "objektiv-mk1", sans-serif;
    font-weight: 400;
    font-size: 29px;
    max-width: 970px;   
    margin-top: 0;
    padding-top: 0;

    @media (max-width: 400px) {
        font-size: 24px;
    }
`

const NextPageText = styled.h3`
    color: white;
    margin-top: 14px;

    @media (max-width: 440px) {
        font-size: 22px;
    }
`

const VisitSL = styled(VisitButton)`
    color: white;
    border-color: white;
    font-family: "objektiv-mk1", sans-serif;
    font-weight: 500;
    padding: 10px 24px;

    :hover {
        background: white;
        color: #2BAFBA;
    }
`

const SLLogo = styled.img`
    max-width: 341px;
    width: calc(100% - 48px);
    height: auto;
    padding: 24px;
`

const NextArrow = styled.img`
    margin: 0;
    padding: 0;
`

const BottomLeft = styled.img`
    z-index: 0;
    width: auto;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;

    @media (max-width: 760px) {
        display: none;
    }
`

const BottomRight = styled.img`
    z-index: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    width: auto;
    height: auto;

    @media (max-width: 760px) {
        width: 172px;
        display: none;
    }
`