import { Background, NavPanel, Headline, NextPage, TopRow, VisitButton } from '../Styled'
import styled from 'styled-components'
import React from 'react'
import NextArrowImg from '../../images/next_arrow.svg'

export default function LogicalHoldings() {
    function scrollToMarra() {
        window.scrollBy({left: 0, top: window.innerHeight, behavior: 'smooth'})
    }
    function scrollToSL() {
        window.scrollBy({left: 0, top: (window.innerHeight * 2), behavior: 'smooth'})
    }
    function scrollToContact() {
        window.scrollBy({left: 0, top: (window.innerHeight * 3), behavior: "smooth"})
    }

    return (
        <LH>
            <TopRow>
                <Title>Logical Holdings</Title>
                <NavPanel>
                    <ul>
                        <li className="active">Logical Holdings</li>
                        <li><button onClick={() => scrollToMarra()}>Marra</button></li>
                        <li><button onClick={() => scrollToSL()}>Scott Logic</button></li>
                        <li><button onClick={() => scrollToContact()}>Contact Us</button></li>
                    </ul>
                </NavPanel>
            </TopRow>
            <LHHeadline>Logical Holdings is the parent company of two exceptional technology firms operating globally from their HQs in North East England.</LHHeadline>
            <ContactLH onClick={() => scrollToContact()} target="_blank">Contact Us</ContactLH>
            <NextPage onClick={() => scrollToMarra()}>
                <NextPageText>Meet Marra</NextPageText>
                <NextArrow src={NextArrowImg} />
            </NextPage>
        </LH>
    )
};

const LH = styled(Background)`
    background: #1B1C1D;
`

const Title = styled.h1`
    color: white;
    
    :hover {
        cursor: default;
    }
`

const LHHeadline = styled(Headline)`
    color: white;
    font-size: 35px;
    max-width: 760px;
    font-weight: 500;
    font-family: "Red Hat Text", sans-serif;

    @media (max-width: 400px) {
        font-size: 24px;
    }
`

const NextPageText = styled.h3`
    color: white;
    font-family: "Red Hat Text", sans-serif;

    @media (max-width: 440px) {
        font-size: 22px;
    }
`

const NextArrow = styled.img`
    margin: 0;
    padding: 0;
`

const CompanyNumber = styled.p`
    position: absolute;
    left: 20px;
    bottom: 0;
    font-family: "Red Hat Text", sans-serif;
    color: white;
    font-size: 12px;
`

const ContactLH = styled(VisitButton)`
    color: white;
    border-color: white;
    font-family: "objektiv-mk1", sans-serif;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 30px;

    :hover {
        background: white;
        color: #1B1C1D;
    }
`