import { Background, NavPanel, Headline, NextPage, TopRow, VisitButton } from '../Styled'
import styled from 'styled-components'
import React from 'react'

import MarraLogoImg from '../../images/Marra_Logo.svg'
import NextArrowImg from '../../images/black_arrow.svg'
import BottomLeftImg from '../../images/marra_arrows.svg'
import BottomRightImg from '../../images/marra_right.png'

export default function Marra() {
    function scrollToLH() {
        window.scrollBy({left: 0, top: -(window.innerHeight * 2), behavior: 'smooth'})
    }
    function scrollToSL() {
        window.scrollBy({left: 0, top: (window.innerHeight * 1), behavior: 'smooth'})
    }
    function scrollToContact() {
        window.scrollBy({left: 0, top: (window.innerHeight * 2), behavior: "smooth"})
    }
    return (
        <MarraContainer>
            <TopRow>
                <Title>Logical Holdings</Title>
                <NavPanelMarra>
                    <ul>
                        <li><button onClick={() => scrollToLH()}>Logical Holdings</button></li>
                        <li className="active">Marra</li>
                        <li><button onClick={() => scrollToSL()}>Scott Logic</button></li>
                        <li><button onClick={() => scrollToContact()}>Contact Us</button></li>
                    </ul>
                </NavPanelMarra>
            </TopRow>
            <MarraLogo src={MarraLogoImg} />
            <LHHeadline>Changing the face of technology, for good</LHHeadline>
            <VistMarra href="http://marra.co.uk" target="_blank">Visit marra.co.uk</VistMarra>
            <NextPage onClick={() => scrollToSL()}>
                <NextPageText>Meet Scott Logic</NextPageText>
                <NextArrow src={NextArrowImg} />
            </NextPage>
            <BottomLeft src={BottomLeftImg} />
            <BottomRight src={BottomRightImg} />
        </MarraContainer>
    )
};

const MarraContainer = styled(Background)`
    background: white;
`

const Title = styled.h1`
    color: black;
`

const LHHeadline = styled(Headline)`
    color: black;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;

    @media (max-width: 400px) {
        font-size: 24px;
    }
`

const NextPageText = styled.h3`
    color: black;

    @media (max-width: 440px) {
        font-size: 22px;
    }
`

const NavPanelMarra = styled(NavPanel)`
    li {
        color: black;
    }

    li button {
        color: black;
    }

    li.active::before {
        color: black;
        position: absolute;
        left: -22px;
    }
`   

const VistMarra = styled(VisitButton)`

`

const MarraLogo = styled.img`
    width: 283px;
    height: auto;
`

const NextArrow = styled.img`
    margin: 0;
    padding: 0;
`

const BottomLeft = styled.img`
    z-index: 0;
    width: auto;
    height: auto;
    position: absolute;
    bottom: -5px;
    left: 0;

    @media (max-width: 880px) {
        display: none;
    }
`

const BottomRight = styled.img`
    z-index: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    width: auto;
    height: auto;

    @media (max-width: 880px) {
        display: none;
    }
`